html {
  font-family: 'Helvetica Neue', Helvetica, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'PingFangSC-Light', Hiragino Sans GB, Microsoft YaHei, STHeiTi, Arial, sans-serif;
}
body,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
input[type='radio'],
input[type='checkbox'] {
  margin: 0;
}
ol,
ul {
  padding: 0;
}
body {
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
}
li {
  list-style-type: none;
}
input,
button,
textarea,
select,
option {
  font: inherit;
  outline: 0;
  appearance: none;
}
button {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
a {
  -webkit-touch-callout: none;
  outline: 0;
  text-decoration: none;
}
em,
i {
  font-style: normal;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
