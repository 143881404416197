#root {
  min-height: calc(100vh - 62px);
}

.cva-video-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cva-video-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.word-input {
  margin-top: 10px;
}

.word-input textarea {
  height: 100px !important;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title {
  padding-left: 15px;
  padding-right: 15px;
  z-index: 1000;
  cursor: default;
}

.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title .per-sixty-end {
  width: 1px;
}

.cva-audit-result-content .time-line-item {
  position: relative;
}

.cva-audit-result-content .fragment {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 12px;
  background-color: #006eff;
  cursor: pointer;
  z-index: 1000;
}

.cva-video-detail .content-wrap .aside-left {
  width: 709px;
}

.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item {
  padding-left: 15px;
  padding-right: 15px;
  user-select: none;
}

.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item .time-line-item-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.cva-audit-result-content .time-line-section-wrap .time-line-section .drag-bar-wrap .drag-bar {
  /* transition: 0.3s; */
  z-index: 1001;
}

.cva-object-detail .position-detail-info .position-info-list {
  position: relative;
}

.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-text-content-wrap {
  position: relative;
}

.cva-video-detail .content-wrap .aside-left .video-wrap {
  position: relative;
}

.cva-video-detail .content-wrap .aside-left .video-wrap .video-bound-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  margin: auto;
}

.cva-video-detail .content-wrap .aside-left .video-wrap .video-bound-wrap .bound-item {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 12px;
  height: 12px;
  border: 2px solid #45f;
  box-sizing: border-box;
  pointer-events: none;
}

.cva-video-detail .cva-disable-tab-desc {
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
}

.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .tag-section {
  min-height: 24px;
  align-items: center;
}

.analy-highlight-video-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cva-video-detail
  .content-wrap
  .aside-left
  .result-content
  .analyze-result
  .cover-wrap
  .img-list
  .t-image__wrapper--need-hover {
  width: calc((100% - 8px * 2) / 3);
  min-height: 50px;
}

body .t-image-viewer-preview-image .t-image-viewer__modal--mask {
  background-color: rgba(0, 0, 0, 0.8);
}
