body {
  position: relative;
  min-width: 1200px;
  min-height: 710px;
  height: 100%;
  background: #12141a;
}
/* 通用布局样式 */
.cva-body-content {
  padding: 60px 0;
}
.cva-body-content .cva-content-wrap {
  padding: 0 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
/* 介绍页样式 */
.cva-introduce__body {
  width: 100%;
  height: calc(100vh - 62px);
  background: url('../assets/introduce-bg.jpg');
  background-size: cover;
}
.cva-introduce__body .cva-introduce__content {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  padding: 298px 10px 348px 10px;
  margin: 0 auto;
}
.cva-introduce__body .cva-introduce__content .img__wrap {
  width: 453px;
  height: 264px;
}
.cva-introduce__body .cva-introduce__content .img__wrap img {
  width: 100%;
  height: 100%;
}
.cva-introduce__body .cva-introduce__content .btn__start {
  margin: 64px 0 0 24px;
}
/* 首页添加自定义人物对话框 */
.cva-add-figure-dialog .section-column {
  margin: 15px 0;
  display: flex;
  align-items: center;
}
.cva-add-figure-dialog .section-column .label {
  width: 96px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
}
.cva-add-figure-dialog .section-column .form-section {
  flex: 1;
  /* 覆盖 TDesign 样式 */
}
.cva-add-figure-dialog .section-column .form-section .name-input-wrap {
  width: 150px;
}
.cva-add-figure-dialog .section-column .form-section .tips-section {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
}
.cva-add-figure-dialog .section-column .form-section .tips-section .text {
  padding: 5px 0;
  font-size: 14px;
  line-height: 22px;
}
.cva-add-figure-dialog .section-column .form-section .t-upload__card .t-upload__card-item {
  margin-bottom: 8px;
}
.cva-add-figure-dialog .section-column .form-section .t-upload__card .t-upload__card-item:first-of-type {
  cursor: default;
}
.cva-add-figure-dialog
  .section-column
  .form-section
  .t-upload__card
  .t-upload__card-item:first-of-type
  .t-upload__card-content
  .t-upload__card-mask {
  display: none;
}
/* 首页 */
.cva-home-video-card-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}
.cva-home-video-card-list .video-card-wrap {
  width: calc((100% - 18px * 2) / 3);
}
/* 首页登录区域 */
.cva-home-login-section {
  padding-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cva-home-login-section .icon-section {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cva-home-login-section .text {
  padding: 20px 0 40px 0;
  font-size: 16px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.8);
}
/* 首页 */
.cva-home-my-ai-library {
  min-height: 820px;
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.05);
}
.cva-home-my-ai-library .tab-panel-content {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 30px;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize {
  display: block;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .btn {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .text {
  margin: 16px 0;
  color: rgba(255, 255, 255, 0.4);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__label {
  color: rgba(255, 255, 255, 0.8);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__input {
  border-color: #9397a2;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__input:after {
  border-color: #9397a2;
}
.cva-home-my-ai-library .tab-panel-content .public-section .title {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  position: relative;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .input-wrap {
  width: 218px;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .search-wrap {
  width: 250px;
  position: absolute;
  right: 0;
  top: 0;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .search-wrap .search-icon {
  width: 32px;
  height: 100%;
  margin: 0 -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .search-wrap .search-icon:hover {
  color: #006eff;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .figure-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .figure-list .card-wrap {
  width: 93px;
  position: relative;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .figure-list .card-wrap .delete-btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
.cva-home-my-ai-library .tab-panel-content .public-section .operate-section .tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize {
  display: block;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .btn {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .text {
  margin: 16px 0;
  color: rgba(255, 255, 255, 0.4);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__label {
  color: rgba(255, 255, 255, 0.8);
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__input {
  border-color: #9397a2;
}
.cva-home-my-ai-library .tab-panel-content.audit-customize .checkbox .t-checkbox__input:after {
  border-color: #9397a2;
}
/* 视频详情页 */
.cva-video-detail .content-wrap {
  margin-top: 20px;
  display: flex;
  gap: 12px;
  /* 左侧 */
  /* 右侧 */
}
.cva-video-detail .content-wrap .aside-left {
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* 视频 */
  /* 分析结果区域 */
}
.cva-video-detail .content-wrap .aside-left .video-wrap {
  width: 100%;
  height: 398px;

  background: rgba(255, 255, 255, 0.05);
}
.cva-video-detail .content-wrap .aside-left .video-wrap video {
  width: 100%;
  height: 100%;
}
.cva-video-detail .content-wrap .aside-left .result-content {
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.05);
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result {
  padding: 35px 37px 44px 37px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .tag-section {
  display: flex;
  align-items: baseline;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .tag-section .title {
  width: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.4;
  flex: none;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .tag-section .tag-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .cover-wrap .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.4;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .cover-wrap .img-list {
  margin-top: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cva-video-detail .content-wrap .aside-left .result-content .analyze-result .cover-wrap .img-list img {
  /* width: calc((100% - 8px * 2) / 3); */
  height: 116px;
  object-fit: cover;
}
.cva-video-detail .content-wrap .aside-right {
  width: 459px;
  background: rgba(255, 255, 255, 0.05);
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap {
  height: 100%;
  padding: 24px 20px 44px 20px;
  display: flex;
  flex-direction: column;
  /* 覆盖 TDesign 卡片样式的 radio 的字体大小 */
  /* 二级 tab 的公共样式 */
  /* 语音识别二级 tab 下的样式 */
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .t-radio-group.t-size-m .t-radio-button {
  font-size: 12px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-tag-wrap {
  max-height: 228px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: auto;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-text-content-wrap {
  max-height: 558px;
  min-height: 228px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: auto;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-text-content-wrap .text-item {
  padding: 20px 36px 20px 20px;
  cursor: pointer;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item
  .time {
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item
  .text {
  margin-top: 8px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.text-item__similarity {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.text-item__similarity
  .text {
  margin: 0;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.text-item__tag {
  padding: 20px;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.text-item__tag
  .tag-wrap {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.selected {
  background: rgba(255, 255, 255, 0.1);
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.selected
  .time {
  color: #006eff;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item.selected
  .t-tag--default {
  background: #12141a;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item:hover {
  background: rgba(255, 255, 255, 0.1);
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item:hover
  .time {
  color: #006eff;
}
.cva-video-detail
  .content-wrap
  .aside-right
  .tab-panel-wrap
  .second-tab-content
  .public-text-content-wrap
  .text-item:hover
  .t-tag--default {
  background: #12141a;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-object-list {
  padding: 6px 0 8px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-object-list .object-card-wrap {
  width: calc((100% - 10px * 3) / 4);
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-object-detail {
  margin: 0 -20px;
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-video-clip-list {
  width: 100%;
  max-height: 558px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-content .public-desc-text {
  max-height: 558px;
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
  overflow: auto;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-voice-content .switch-text {
  display: flex;
  gap: 20px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap .second-tab-voice-content .switch-text .title {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap-audit .section {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap-audit .section .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.cva-video-detail .content-wrap .aside-right .tab-panel-wrap-audit .section.list-box {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  max-height: 558px;
  min-height: 228px;
  overflow: auto;
}
/* 我的智能库中使用到的自定义添加按钮组件 */
.button-add {
  height: 32px;
  width: 218px;
  padding-left: 15px;
  border: 1px dashed #5e5e5e;
  border-radius: 3px;
  display: flex;
  gap: 15px;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.button-add .text {
  font-size: 14px;
  line-height: 18px;
  color: #006eff;
}
.button-add:hover {
  border: 1px solid #006eff;
}
/* 智能审核的视频分析结果组件 */
.cva-audit-result-content {
  min-height: 151px;
  height: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.1);
}
.cva-audit-result-content .name-section {
  width: 20%;
  border-right: 1px solid #161616;
  display: flex;
  flex-direction: column;
}
.cva-audit-result-content .name-section .name-item {
  height: 30px;
  width: 100%;
  padding: 0 20px 0 10px;
  font-size: 12px;
  line-height: 30px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #161616;
}
.cva-audit-result-content .name-section .name-item.title {
  background: #57585d;
}
.cva-audit-result-content .time-line-section-wrap {
  flex: 1;
  overflow-x: auto;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section {
  width: fit-content;
  display: flex;
  flex-direction: column;
  position: relative;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item {
  height: 30px;
  border-bottom: 1px solid #161616;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title {
  padding-left: 15px;
  padding-right: 15px;

  background: #57585d;
  display: flex;
  flex-wrap: nowrap;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title .per-sixty {
  width: 60px;
  height: 100%;
  background-image: url(https://imgcache.qq.com/open_proj/proj_qcloud_v2/mc_2014/video/other/demo/ai/css/img/scale.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  position: relative;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title .per-sixty-end {
  width: 1px;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .time-line-item.title .per-sixty .scale-text {
  font-size: 12px;
  color: #b7b7b7;
  position: absolute;
  left: 0;
  top: 5px;
  transform: translateX(-50%);
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .drag-bar-wrap {
  width: calc(100% - 30px);
  height: 100%;
  position: absolute;
  left: 15px;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .drag-bar-wrap .drag-bar {
  width: 18px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  cursor: move;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .drag-bar-wrap .drag-bar .btn {
  width: 18px;
  height: 18px;
  background-image: url(https://imgcache.qq.com/open_proj/proj_qcloud_v2/mc_2014/video/other/demo/ai/css/img/icon-drag.svg);
  background-repeat: no-repeat;
}
.cva-audit-result-content .time-line-section-wrap .time-line-section .drag-bar-wrap .drag-bar .line {
  flex: 1;
  width: 1px;
  background-color: #fff;
}
/* 矩形单选按钮组件 */
.cva-checkbox-rect {
  height: 30px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
  cursor: pointer;
}
.cva-checkbox-rect .text {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
}
.cva-checkbox-rect.selected {
  border-color: #006eff;
}
.cva-checkbox-rect.selected .text {
  color: #ffffff;
}
.cva-checkbox-rect:hover {
  border-color: #006eff;
}
.cva-checkbox-rect:hover .text {
  color: #ffffff;
}
/* 视频条组件 */
.cva-video-clip {
  width: 100%;
  height: 116px;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-shrink: 0;
  border: 1px solid transparent;
  cursor: pointer;
}
.cva-video-clip .avatar {
  width: 206px;
  height: 100%;
  object-fit: cover;
}
.cva-video-clip .info {
  width: calc(100% - 206px);
  padding: 0 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.cva-video-clip .info .title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 13px;
  padding-bottom: 5px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.cva-video-clip .info .desc {
  margin-top: 14px;
  display: flex;
  gap: 10px;
}
.cva-video-clip .info .desc .name {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.4);
}
.cva-video-clip .info .desc .time {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
.cva-video-clip.is-select {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.cva-video-clip.has-hover:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
}
/* 人物 | 物体 出现的详细信息组件 */
.cva-object-detail {
  width: 100%;
}
.cva-object-detail .object {
  width: 100%;
  height: 93px;
  display: flex;
}
.cva-object-detail .object .avatar {
  width: 93px;
  height: 100%;
  object-fit: cover;
}
.cva-object-detail .object .detail-info {
  margin-left: 16px;
  width: calc(100% - 93px - 16px);
  height: 100%;
}
.cva-object-detail .object .detail-info .name {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}
.cva-object-detail .object .detail-info .position-title {
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  opacity: 0.4;
}
.cva-object-detail .object .detail-info .position-wrap {
  width: 100%;
  height: 15px;
  margin-top: 10px;
  background: #12141a;
  position: relative;
}
.cva-object-detail .object .detail-info .position-wrap .slider-bar {
  width: 4px;
  height: 100%;
  position: absolute;
  background: #005cff;
  top: 0;
  cursor: pointer;
}
.cva-object-detail .position-detail-info {
  margin-top: 17px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.cva-object-detail .position-detail-info .position-info-list {
  width: 100%;
  max-height: calc(528px - 40px);
  transition: max-height 0.3s ease;
  overflow: auto;
}
.cva-object-detail .position-detail-info .position-info-list.hide {
  max-height: 0;
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item {
  width: 100%;
  height: 62px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item.selected {
  background: rgba(255, 255, 255, 0.1);
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item.selected .time {
  color: #006eff;
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item:hover {
  background: rgba(255, 255, 255, 0.1);
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item:hover .time {
  color: #006eff;
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item .time {
  font-size: 14px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.4);
}
.cva-object-detail .position-detail-info .position-info-list .position-info-item .text {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}
.cva-object-detail .position-detail-info .btn {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.cva-object-detail .position-detail-info .btn .text {
  font-size: 14px;
  line-height: 22px;
  color: #006eff;
}
/* 人物 | 物体 卡片组件 */
.cva-object-card {
  width: 100%;
  height: 120px;
  position: relative;
  border: 2px solid transparent;
  cursor: pointer;
}
.cva-object-card.selected {
  border-color: #006eff;
}
.cva-object-card:hover {
  border-color: #006eff;
}
.cva-object-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cva-object-card .name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
/* 返回上一页组件 */
.cva-back-title {
  padding-bottom: 18px;
  border-bottom: 1px solid #383838;
}
.cva-back-title .link-back {
  display: inline-flex;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}
.cva-back-title .link-back .text {
  margin-left: 14px;
  font-size: 18px;
  line-height: 18px;
}
.cva-back-title .link-back:hover {
  color: #006eff;
}
/* 视频上传组件 */
.cva-video-upload {
  width: 100%;
  height: 215px;
  position: relative;
  background: #000000;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cva-video-upload .upload-icon-wrap {
  width: 48px;
  height: 48px;
  border: 1px dashed rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cva-video-upload .upload-text {
  margin-top: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #006eff;
}
/* 视频卡片组件 */
.cva-video-card {
  width: 100%;
  height: 215px;
  position: relative;
  background: #000000;
  cursor: pointer;
}
.cva-video-card .cover-wrap {
  width: 100%;
  height: 100%;
}
.cva-video-card .cover-wrap img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cva-video-card .button-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  position: absolute;
  top: 80px;
}
.cva-video-card .analyze-info {
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 102px;
}
.cva-video-card .error-tips-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 81px;
}
.cva-video-card .error-tips-wrap .t-alert {
  align-items: center;
}
.cva-video-card .name-wrap {
  padding-left: 13px;
  width: 100%;
  height: 32px;
  background: rgba(0, 0, 0, 0.85098);
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.cva-video-card .name-wrap .name {
  width: calc(100% - 32px);
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cva-video-card .name-wrap .delete-btn {
  position: absolute;
  right: 8px;
  transform: scale(0.9);
}
.cva-video-card .name-wrap .delete-btn:hover {
  transform: scale(1.1);
}
